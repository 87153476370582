import React from "react";
import PrimaryButton from "../Button/PrimaryButton";


const RecommendationItem = ({ image, title, subTitle, action }: { image: string, title: string, subTitle?: string, action?: string }) => (
    <div className='flex flex-col gap-3 '>
      <img className="rounded-[10px]" src={image} alt={title} 
      style={{ width:"100%", height: "200px"}} />
      <div className="flex flex-col  gap-2 ">
        <span className='text-[#707070] font-semibold  text-start'>{title}</span>
        <span className="text-[#707070] text-[16px] text-left">{subTitle}</span>
        { action && <div className="mt-auto"><PrimaryButton label={action} /></div>}
      </div>
    </div>
);

export default RecommendationItem;




// import React from "react";
// import sanitizeHtml from "sanitize-html";
// import PrimaryButton from "../Button/PrimaryButton";

// const RecommendationItem = ({
//   image,
//   title,
//   subTitle,
//   action,
//   className,
// }: {
//   image: string;
//   title: string;
//   subTitle?: string;
//   action?: string;
//   className?: string;
// }) => {
//   const maxLength = 45;

//   // Fonction pour assainir le texte HTML
//   const sanitizeSubTitle = (html: string) => {
//     return sanitizeHtml(html, {
//       allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'br'], // Personnalisez les balises autorisées
//       allowedAttributes: {
//         a: ['href']
//       }
//     });
//   };

//   return (
//     <div
//       className={`${className} relative rounded-lg overflow-hidden shadow-md`}
//       style={{
//         height: "450px",
//         maxHeight: "450px",
//         borderRadius: "10px",
//         boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.4)",
//       }}
//     >
//       <img
//         src={image}
//         alt={title}
//         className="object-cover"
//         style={{ width: "100%", height: "230px" }}
//       />
//       <div className="p-4">
//         <div className="font-semibold">{title}</div>
//         {subTitle && (
//           <div className="text-[10px] mb-3">
           
//             <div
//               dangerouslySetInnerHTML={{
//                 __html: sanitizeSubTitle(
//                   subTitle.length > maxLength
//                     ? subTitle.slice(0, maxLength) + "..."
//                     : subTitle
//                 ),
//               }}
//             />
//           </div>
//         )}
//         {action && (
//           <div className="mt-auto">
//             <PrimaryButton label={action} />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default RecommendationItem;