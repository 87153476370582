import { useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import sanitizeHtml from "sanitize-html";
import { BlogsService } from "../../APIs";
import "./style.css";
interface BlogData {
  title: string;
  meta_title: string;
  meta_description: string;
  blogText: string;
  image: string;
  bloglink: string;
}

const BlogDetails = () => {
  const [blog, setBlog] = useState<BlogData | null>(null);
  const { bloglink } = useParams();
  const encodedUrl = bloglink ? bloglink.replace(/-/g, " ") : "";

  const capitalizeFirstLetter = (str: string) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const fetchBlogData = async (bloglink: string) => {
    try {
      const encodedUrl = bloglink ? decodeURIComponent(bloglink) : "";
      const response = await BlogsService.getBlogByLink(encodedUrl);
      if (response.data) {
        setBlog(response.data);
      } else {
        console.error("Error fetching blog:", response.error);
      }
    } catch (error) {
      console.error("Error fetching blog:", error);
    }
  };

  useEffect(() => {
    if (encodedUrl) {
      fetchBlogData(encodedUrl);
    }
  }, [encodedUrl]);

  function toUrlSlug(text: string) {
    const decoded = decodeURIComponent(text);
    const hyphenated = decoded.replace(/\s+/g, "-");
    return hyphenated;
  }

  const sanitizeBlogText = (html: string) => {
    return sanitizeHtml(html, {
      allowedTags: ["b", "i", "em", "strong", "a", "p", "br"],
      allowedAttributes: {
        a: ["href"],
      },
    });
  };

  return (
    <div>
      {blog && (
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{blog.meta_title}</title>
            <meta name="description" content={blog.meta_description} />
            <link
              rel="canonical"
              href={`https://www.sodastream.co.za/${toUrlSlug(blog.bloglink)}`}
            />
          </Helmet>
          <Header
            title={
              capitalizeFirstLetter(blog.title) === "Sparkling Water"
                ? "What are the Benefits of Sparkling Water?"
                : blog.title === "Stay Hydrated at work"
                ? "The Sparkling Blog"
                : blog.title === "Benefits of Drinking 8 Glasses of water a day"
                ? "Benefits of Drinking 8 Glasses of water a day"
                : blog.title ===
                  "A mini kitchen revamp: 20 budget-friendly makeover ideas for a stylish and functional space"
                ? "Mini Kitchen Revamp: Top 20 Budget Friendly Makeover Ideas"
                : blog.title
            }
            subTitle=""
            textBackground="BLOG"
            backgroundImg={blog.image}
           
          />
          <div className="relative min-h-[auto] w-full ">
            <div
              className="relative mt-[-12%] top-0 gap-10  z-9 flex flex-col 
            items-center justify-center  w-full">
              <div className="mt-5 lg:px-20 md:px-10 sm:px-2 px-5 w-full">
                <div
                  className="bg-white rounded-xl border border-gray-300 
                relative w-full px-5 py-10 text-[#005D6E]">
                  <div className="flex flex-col text-start">
                    <div
                      className="blog-content p-4 "
                      dangerouslySetInnerHTML={{ __html: blog.blogText }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default BlogDetails;