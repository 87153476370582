import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Images } from "../../assets/assets";
import RecommendationItem from "../../components/RecommendationItem/RecommendationItem";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { BlogsService } from "../../APIs";
import { Helmet } from "react-helmet";
import queryString from "query-string";
const recommendations = [
  {
    image: Images.Benefits1GallonWater,
    title: "The Benefits of Drinking 8 Glasses of Water a Day",
    link: "/blog/1",
    action: "READ MORE",
  },
  {
    image: Images.StayHydratedPost,
    title: "Staying Hydrated at Work",
    link: "/blog/2",
    action: "READ MORE",
  },
  {
    image: Images.Post2,
    title: "Sparkling Water",
    link: "/blog/3",
    action: "READ MORE",
  },
];

const BlogsPage = () => {
  const [blogs, setBlogs] = useState<any[]>([]);

  const fetchBlogsData = async () => {
    try {
      const { data, error } = await BlogsService.getAllBlogs();
      if (data) {
        setBlogs(data);
      } else {
        console.error("Error fetching blogs:");
      }
    } catch (error) {
      console.error("Error fetching :");
    }
  };

  // Call the method
  useEffect(() => {
    fetchBlogsData();
  }, []);

  function toUrlSlug(text: string) {
    const decoded = decodeURIComponent(text);
    const hyphenated = decoded.replace(/\s+/g, "-");
    return hyphenated;
  }

  const capitalizeFirstLetter = (str: string) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"SodaStream Blog | SodaStream SA"}</title>
        <meta
          name="description"
          content={
            "Stay updated with SodaStream's latest news, tips, and recipes. Explore our sparkling blog for exciting articles!"
          }
        />
        <link rel="canonical" href={"https://www.sodastream.co.za/blog"} />
      </Helmet>
      {blogs.length > 0 && (
        <div>
          <Header
            textBackground="SPARKLING"
            backgroundImg={
              "http://res.cloudinary.com/brabenjos/image/upload/v1723093154/sodastream/1723093153077.png"
            }
            title={"The Sparkling Blog"}
          />
        </div>
      )}

   
<section>
        <div className="bg-[#EEEAE5 mt-[-10%] relative  z-30 px-5 md:px-20 py-10 flex flex-col gap-4">
         
          <div className="flex flex-col md:flex-row gap-10">
            {blogs.map((recommendation, index) => (
              <Link
                to={`/blog/${toUrlSlug(recommendation.bloglink)}`}
                key={index}
                className="basis-full md:basis-[24%]">
                <RecommendationItem
                  image={recommendation.image}
                  title={recommendation.title}
                  subTitle={recommendation.subTitle}
                />
              </Link>
            ))}
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default BlogsPage;
